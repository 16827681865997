import classNames from 'classnames';
import React from 'react';
import { FadeInWrapper } from './animation/FadeInWrapper';

import * as classes from './sectionHeader.module.scss';

export function SectionHeader({ content }) {
   return (
      <FadeInWrapper>
         <div className={classNames(classes.contentHeader, 'text-center')}>{content}</div>
      </FadeInWrapper>
   );
}
