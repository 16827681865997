import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import { backgroundIntentMap, outlineIntentMap, textIntentMap } from '../../constants';

import * as classes from './flowToContact.module.scss';

export function FlowToContact({ variant }) {
   const { t } = useTranslation('global');

   return (
      <div className={classNames(classes.container, backgroundIntentMap[variant], textIntentMap[variant])}>
         <FontAwesomeIcon icon="comments" size="4x" className="mb-4" />
         <h1 className={classNames(textIntentMap[variant])}>{t('flowToContact.title')}</h1>
         <p className={classNames(textIntentMap[variant])}>{t('flowToContact.text')}</p>
         <Link to="/contact">
            <Button variant={outlineIntentMap[variant]}>{t('flowToContact.button')}</Button>
         </Link>
      </div>
   );
}
