import React from 'react';
import { Parallax } from 'rc-scroll-anim';

export function FadeInWrapper({ children }) {
   return (
      <Parallax always={true} animation={{ opacity: 1, playScale: [0, 0.7] }} style={{ opacity: 0 }}>
         {children}
      </Parallax>
   );
}
